<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar
          title="答题排行榜"
          left-arrow fixed
          @click-left="goBack"
      />
    </div>

    <div class="bodyWarp">
      <div class="rankingList">
        <div class="Top" v-for="(item,index) in ranking" :key="item.userId" :class="'top'+(index+1)">
          <div class="img">
            <img :src="item.userHead"/>
          </div>
        </div>
      </div>
      <div class="jiangtai">
        <div v-for="(item,index) in ranking" :key="item.userId" :class="'title'+(index+1)">
          <p class="tel">{{ item.userName }}</p>
          <p class="topic">{{ item.questionsNumber }}道题</p>
        </div>
      </div>
      <div class="list">
        <div class="div" v-for="(item,i) in list">
          <div class="span">{{ i+4 }}</div>
          <img :src="item.userHead">
          <div class="p">
            <p class="p1">{{ item.userName }}</p>
            <p class="p2">{{ item.questionsNumber }}道题</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {ranking_list,} from "@/api/questionBank.js";

export default {
  data() {
    return {
      ranking: [],
      list: []
    }
  },
  name: 'rankingList',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    ranking_list() {
      this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration:0
      });
      ranking_list(this.secondId).then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data;
          this.ranking = this.list.splice(0,3);
          this.$toast.clear();
        }else{
          this.$toast.clear();
        }
      })
    }
  },
  mounted(){
    this.ranking_list();
  },
  created() {
    this.secondId = window.localStorage.getItem('secondId');
    
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;

}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

.bodyWarp {
  margin-top: 42px;
  background: #5F7DFF;
  height: calc(100vh - 66px);
  overflow-y: auto;
  padding: 20px 22px 0;
  .rankingList {
    height: 130px;
    position: relative;
    background: url("../../../assets/all_Icon/drawable-xxhdpi/ranking_list_star.png") no-repeat 100% 100%/100%;

    .Top {
      position: absolute;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .top1 {
      top: 27px;
      left: 155px;

      .img {
        width: 81px;
        height: 81px;
        position: relative;
        background: url("../../../assets/all_Icon/drawable-xxhdpi/first_bg.png") no-repeat 50% 50%/90%;
        img{
          width: 61px;
          height: 61px;
          position: absolute;
          left: 7px;
          bottom: 3px;
        }
      }
    }

    .top2 {
      top: 56px;
      left: 59px;

      .img {
        width: 71px;
        height: 71px;
        position: relative;
        background: url("../../../assets/all_Icon/drawable-xxhdpi/second_bg.png") no-repeat 50% 50%/90%;
        img{
          width: 51px;
          height: 51px;
          position: absolute;
          left: 7px;
          bottom: 3px;
        }
      }
    }

    .top3 {
      top: 80px;
      left: 269px;

      .img {
        width: 61px;
        height: 61px;
        position: relative;
        background: url("../../../assets/all_Icon/drawable-xxhdpi/third_bg.png") no-repeat 50% 50%/90%;
        img{
          width: 41px;
          height: 41px;
          position: absolute;
          left: 7px;
          bottom: 3px;
        }
      }
    }
  }

  .jiangtai{
    position: relative;
    height: 103px;
    width: 333px;
    margin: 0 auto;
    background: url("../../../assets/all_Icon/drawable-xxhdpi/ranking_list_prize.png") no-repeat 50% 50%/90%;

    div{
      position: absolute;
    }

    .title1{
      bottom: 26px;
      left: 140px;
    }

    .title2,.title3{
      bottom: 10px;
    }

    .title2{
      left: 30px;
    }

    .title3{
      right: 30px;
    }

    .tel {
      //width: 66px;
      height: 15px;
      font-size: 11px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 15px;
    }

    .topic {
      //width: 66px;
      height: 23px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 23px;
    }
  }

  .list{
    height: 403px;
    background: #FFFFFF;
    border-radius: 14px;
    margin: -2px auto 0;
    padding: 32px 22px 0;

    .div{
      margin-bottom: 22px;
      .span{
        display: inline-block;
        width: 9px;
        height: 39px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 39px;
        vertical-align: text-bottom;
      }

      img{
        width: 40px;
        height: 39px;
        border-radius: 50%;
        margin: 0 14px 0 12px;
      }

      .p{
        display: inline-block;
        vertical-align: super;
        .p1{
          height: 22px;
          font-size: 15px;
          font-weight: 500;
          color: #141414;
          line-height: 22px;
        }
        .p2{
          height: 18px;
          font-size: 13px;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
        }
      }
    }
  }

}

</style>
